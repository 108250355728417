<template>
  <v-card
    class="card-shadow border-radius-xl mt-6"
    id="basic"
    :loading="loading"
  >
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">{{ $t("sensor") }}</h5>
    </div>
    <validation-observer ref="form">
      <v-form v-model="valid" :lazy-validation="true" class="px-6 pb-6 pt-0">
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="name"
              rules="required"
            >
              <v-text-field
                v-model="sensor.name"
                :label="$t('sensorData.name')"
                :error-messages="errors"
                required
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="address"
              rules="required"
            >
              <v-text-field
                v-model="sensor.address"
                :label="$t('sensorData.address')"
                :error-messages="errors"
                required
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0">
            <validation-provider
              v-slot="{ errors }"
              name="description"
              rules="required"
            >
              <v-text-field
                v-model="sensor.description"
                :label="$t('sensorData.description')"
                :error-messages="errors"
                required
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0 d-flex justify-center align-center">
            <v-card-actions>
              <v-btn
                :elevation="0"
                color="#2196f3"
                class="
                  font-weight-bold
                  text-white
                  py-5
                  px-12
                  my-auto
                  ms-md-auto
                  mr-md-12
                "
                small
                @click="handleSubmit"
                :loading="loading"
                :disabled="!valid"
              >
                {{ $t("saveChanges") }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "sensorData",
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      sensor: {
        name: "",
        address: "",
        description: "",
      },
      valid: false,
    };
  },
  methods: {
    ...mapActions(["setError", "setSuccess"]),
    async handleSubmit() {
      this.valid = await this.$refs.form.validate();

      if (!this.valid) {
        return;
      }

      this.loading = true;
      if (this.id) {
        this.updateSensor();
      } else {
        this.addSensor();
      }
    },
    async addSensor() {
      this.loading = true;
      const requestBody = {
        name: this.sensor.name,
        address: this.sensor.address,
        description: this.sensor.description,
      };
      try {
        await this.$api.sensor.postSensor(requestBody);
        this.loading = false;
        this.setSuccess();
        this.$router.push("/sensors");
      } catch (error) {
        this.setError(error);
      }
    },
    async fetchSensor() {
      this.loading = true;
      try {
        const response = await this.$api.sensor.getSensor(this.id);
        this.loading = false;
        this.sensor = response.data;
      } catch (error) {
        this.loading = false;
        this.setError(error);
      }
    },
    async updateSensor() {
      const requestBody = {
        name: this.sensor.name,
        address: this.sensor.address,
        description: this.sensor.description,
      };
      try {
        await this.$api.sensor.putSensor(this.id, requestBody);
        this.loading = false;
        this.setSuccess();
        this.$router.push("/sensors");
      } catch (error) {
        this.setError(error);
      }
    },
  },
  mounted() {
    if (this.id) {
      this.fetchSensor();
    }
  },
};
</script>
