<template>
  <v-container v-if="!loading" fluid class="py-6 mt-3">
    <v-row class="d-flex aling-center mb-8">
      <v-col md="4" class="my-auto text-end ml-auto">
        <v-btn
          :elevation="0"
          :to="'/sensors/new'"
          color="#2196f3"
          class="font-weight-bold text-white py-5 px-5 my-auto ms-auto"
          small
        >
          <v-icon class="material-icons-round me-3">add</v-icon>
          {{ $t("add") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <sensor-card
        v-for="sensor in sensors"
        :key="sensor.title"
        :sensor="sensor"
      ></sensor-card>
    </v-row>
  </v-container>
  <loading v-else />
</template>
<script>
import Loading from "@/components/Loading.vue";
import sensorsMixin from "@/mixins/sensorsMixin";
import { mapActions } from "vuex";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
import SensorCard from "@/components/sensors/SensorCard.vue";
Vue.use(VueSweetalert2);
export default {
  name: "Sensors",
  components: {
    Loading,
    SensorCard,
  },
  data: () => ({
    loading: true,
  }),
  mixins: [sensorsMixin],
  mounted() {
    this.fetchSensors();
  },
  methods: {
    ...mapActions(["setSuccess", "setError"]),
  },
};
</script>
