<template>
  <v-card class="shifts-table">
    <v-data-table
      :headers="headers"
      :items="dataPage.data"
      :search="search"
      class="table"
      :page.sync="page"
      hide-default-footer
      @page-count="pageCount = $event"
      :items-per-page="itemsPerPage"
      mobile-breakpoint="0"
      :disable-sort="$vuetify.breakpoint.xs"
      no-data-text="Nenhum registro encontrado"
      :loading="loadingDataTable"
    >
      <template v-slot:top>
        <v-toolbar flat height="80" class="border-radius-xl">
          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                hide-details
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                "
                dense
                flat
                filled
                solo
                height="43"
                v-model="search"
                :placeholder="$t('search')"
              >
                <template slot="prepend-inner">
                  <v-icon
                    color="#adb5bd"
                    size="18px"
                    class="material-icons-round mt-1"
                    >search</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-toolbar>
      </template>
    </v-data-table>
    <v-card-actions class="card-padding">
      <v-row>
        <v-col cols="6" lg="3" class="d-flex align-center">
          <span class="text-body me-3 text-sm">{{ $t("itemsPerPage") }}:</span>
          <v-text-field
            hide-details
            type="number"
            outlined
            min="-1"
            max="15"
            background-color="rgba(255,255,255,.9)"
            color="rgba(0,0,0,.6)"
            light
            :value="itemsPerPage"
            @input="itemsPerPage = parseInt($event, 10) || 1"
            :placeholder="$t('itemsPerPage')"
            class="
              font-size-input
              placeholder-lighter
              text-color-light
              input-alternative input-focused-alternative input-icon
            "
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" class="ml-auto d-flex justify-end">
          <v-pagination
            prev-icon="fa fa-angle-left"
            next-icon="fa fa-angle-right"
            class="pagination"
            color="rgb(33, 150, 243)"
            v-model="page"
            :length="pageCount || 0"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ShiftsTable",
  props: {
    dataPage: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: "",
    };
  },
  computed: {
    ...mapGetters({
      loadingDataTable: "loadingDataTable",
    }),
    headers: (vm) => [
      {
        text: `${vm.$t("company")}`,
        align: "start",
        cellClass: "border-bottom",
        sortable: true,
        value: "company",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
      },
      {
        text: `${vm.$t("shift")}`,
        align: "start",
        cellClass: "border-bottom",
        sortable: true,
        value: "shift",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
      },
      {
        text: `${vm.$t("tank")}`,
        align: "start",
        cellClass: "border-bottom",
        sortable: true,
        value: "tank",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
      },
      {
        text: `${vm.$t("tankData.measuredIn")}`,
        align: "start",
        cellClass: "border-bottom",
        sortable: true,
        value: "measuredIn",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
      },
      {
        text: `${vm.$t("tankData.measuredVolume")} (${vm.$t(
          "measurementUnits.liters"
        )})`,
        align: "start",
        cellClass: "border-bottom",
        sortable: true,
        value: "measuredVolume",
        class: "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
      },
    ],
  },
};
</script>

<style scoped></style>
