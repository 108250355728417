<template>
  <div>
    <validation-observer>
      <label class="text-sm text-body">{{ $t("report.selectACompany") }}</label>
      <validation-provider v-slot="{ errors }" name="company" rules="required">
        <v-select
          v-model="selectedCompany"
          @input="handleInput"
          :items="companies"
          item-text="name"
          item-value="id"
          :label="label"
          :rules="rules"
          :required="required"
          :error-messages="errors"
          :loading="loading"
          :disabled="loading"
          class="font-size-input input-style"
          single-line
          height="36"
        />
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CompanySelectorInput",
  props: {
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default() {
        return this.$t("report.selectACompany");
      },
    },
  },
  data: () => ({
    loading: false,
    companies: [],
    selectedCompany: null,
  }),
  methods: {
    ...mapActions(["setError"]),
    async fetchCompanies() {
      this.loading = true;
      try {
        const response = await this.$api.company.getCompanies();
        this.companies = response.data;
        this.loading = false;
      } catch (error) {
        this.setError(error);
      }
    },
    handleInput() {
      this.$emit("input", this.selectedCompany);
    },
  },
  mounted() {
    this.fetchCompanies();
  },
};
</script>

<style></style>
