<template>
  <v-card
    class="card-shadow border-radius-xl mt-6 mx-6"
    elevation="5"
    :style="{ borderLeft: `8px solid ${tank.fluidColor} !important` }"
    v-if="!loading"
  >
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">
        {{ tank.name }}: {{ $t(`tankData.fluidTypes.${tank.fluidType}`) }}
      </h5>
    </div>

    <v-tabs
      v-model="tab"
      class="custom-tabs tank-tabs px-5"
      :color="tank.fluidColor"
      grow
    >
      <v-tab
        v-for="(item, index) in tabs"
        :key="index"
        :value="item"
        :label="$t(`tankData.${item}`)"
      >
        {{ $t(`tankData.${item}`) }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="tank-tabs-items mb-10"
      :color="tank.fluidColor"
    >
      <v-tab-item>
        <last-measurement :tank="tank" />
      </v-tab-item>
      <v-tab-item>
        <last-measurements-chart :id="id" />
      </v-tab-item>
      <v-tab-item>
        <last-ends-of-shifts :id="id" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
  <loading v-else />
</template>

<script>
import { mapActions } from "vuex";
import Loading from "@/components/Loading.vue";
import LastMeasurement from "@/components/tanks/LastMeasurement.vue";
import LastMeasurementsChart from "@/components/tanks/LastMeasurementsChart.vue";
import LastEndsOfShifts from "@/components/tanks/LastEndsOfShifts.vue";

export default {
  name: "tank-info",
  components: {
    Loading,
    LastMeasurement,
    LastMeasurementsChart,
    LastEndsOfShifts,
  },
  data: () => ({
    tank: {},
    loading: true,
    tab: 0,
    id: null,
  }),
  computed: {
    tabs() {
      return ["lastMeasurement", "lastMeasurementsChart", "lastEndsOfShifts"];
    },
  },
  methods: {
    ...mapActions(["setError"]),
    async fetchTank() {
      this.loading = true;
      try {
        const response = await this.$api.tank.getTankMeasurements(this.id);
        this.tank = response.data;
        this.loading = false;
      } catch (error) {
        if (error.response.status === 404) {
          this.fetchTankWithoutMeasurements();
          return;
        }
        this.loading = false;
        this.setError(error);
        this.$router.push("/tank");
      }
    },
    async fetchTankWithoutMeasurements() {
      this.loading = true;

      try {
        const response = await this.$api.tank.getTank(this.id);
        this.tank = response.data;
        this.loading = false;
      } catch (error) {
        if (error.response.status === 404) {
          this.fetchTankWithoutMeasurements();
          return;
        }
        this.loading = false;
        this.setError(error);
        this.$router.push("/tank");
      }
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchTank();
  },
};
</script>

<style scoped>
.tank {
  min-height: 83.5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tank >>> .v-card__title {
  font-size: 2rem;
  word-break: break-word;
}
</style>
