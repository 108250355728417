var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-shadow border-radius-xl mt-6",attrs:{"id":"basic","loading":_vm.loading}},[_c('div',{staticClass:"px-6 py-6"},[_c('h5',{staticClass:"text-h5 font-weight-bold text-typo"},[_vm._v(_vm._s(_vm.$t("tankInfo")))])]),_c('validation-observer',{ref:"form"},[_c('v-form',{ref:"form",staticClass:"px-6 pb-6 pt-0",attrs:{"lazy-validation":true},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('label',{staticClass:"text-sm text-body"},[_vm._v("Sensor")]),_c('validation-provider',{attrs:{"name":"tank","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"font-size-input input-style",attrs:{"items":_vm.sensorsList,"item-text":"name","item-value":'id',"label":_vm.$t('sensor'),"error-messages":errors,"single-line":"","required":"","height":"36"},model:{value:(_vm.tank.sensorId),callback:function ($$v) {_vm.$set(_vm.tank, "sensorId", $$v)},expression:"tank.sensorId"}})]}}])})],1),_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('label',{staticClass:"text-sm text-body"},[_vm._v(_vm._s(_vm.$t("tankData.fluidType")))]),_c('validation-provider',{attrs:{"name":"fluidType","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"font-size-input input-style",attrs:{"items":_vm.fluidTypes,"item-text":"text","item-value":"value","label":_vm.$t('tankData.fluidType'),"error-messages":errors,"required":"","single-line":"","height":"36"},model:{value:(_vm.tank.fluidType),callback:function ($$v) {_vm.$set(_vm.tank, "fluidType", $$v)},expression:"tank.fluidType"}})]}}])})],1)],1),_c('v-row',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-size-input input-style",attrs:{"label":_vm.$t('tankData.name'),"error-messages":errors,"required":""},model:{value:(_vm.tank.name),callback:function ($$v) {_vm.$set(_vm.tank, "name", $$v)},expression:"tank.name"}})]}}])})],1),_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"volume","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-size-input input-style",attrs:{"label":((_vm.$t('tankData.volume')) + " (" + (_vm.$t(
                'measurementUnits.liters'
              )) + ")"),"error-messages":errors,"required":"","type":"number"},model:{value:(_vm.tank.volume),callback:function ($$v) {_vm.$set(_vm.tank, "volume", $$v)},expression:"tank.volume"}})]}}])})],1)],1),_c('v-row',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"diameter","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-size-input input-style",attrs:{"label":((_vm.$t('tankData.diameter')) + " (" + (_vm.$t(
                'measurementUnits.millimeters'
              )) + ")"),"error-messages":errors,"required":"","type":"number"},model:{value:(_vm.tank.diameter),callback:function ($$v) {_vm.$set(_vm.tank, "diameter", $$v)},expression:"tank.diameter"}})]}}])})],1),_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"length","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-size-input input-style",attrs:{"label":((_vm.$t('tankData.length')) + " (" + (_vm.$t(
                'measurementUnits.millimeters'
              )) + ")"),"error-messages":errors,"required":"","type":"number"},model:{value:(_vm.tank.length),callback:function ($$v) {_vm.$set(_vm.tank, "length", $$v)},expression:"tank.length"}})]}}])})],1)],1),_c('v-row',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"sensorInstallationHeight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-size-input input-style",attrs:{"label":((_vm.$t('tankData.sensorInstallationHeight')) + " (" + (_vm.$t(
                'measurementUnits.millimeters'
              )) + ")"),"error-messages":errors,"required":"","type":"number"},model:{value:(_vm.tank.sensorInstallationHeight),callback:function ($$v) {_vm.$set(_vm.tank, "sensorInstallationHeight", $$v)},expression:"tank.sensorInstallationHeight"}})]}}])})],1),_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"fluidHeight","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-size-input input-style",attrs:{"label":((_vm.$t('tankData.fluidHeight')) + " (" + (_vm.$t(
                'measurementUnits.millimeters'
              )) + ")"),"error-messages":errors,"required":"","type":"number"},model:{value:(_vm.tank.fluidHeight),callback:function ($$v) {_vm.$set(_vm.tank, "fluidHeight", $$v)},expression:"tank.fluidHeight"}})]}}])})],1)],1),_c('v-row',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('label',{staticClass:"text-sm text-body"},[_vm._v(_vm._s(_vm.$t("tankData.fluidColor")))]),_c('v-color-picker',{attrs:{"dot-size":"25","hide-inputs":"","hide-canvas":"","width":"100%"},model:{value:(_vm.tank.fluidColor),callback:function ($$v) {_vm.$set(_vm.tank, "fluidColor", $$v)},expression:"tank.fluidColor"}})],1),_c('v-col',{staticClass:"pb-0 col-sm-6 d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('v-card-actions',[_c('v-btn',{staticClass:"\n                font-weight-bold\n                text-white\n                py-5\n                px-12\n                my-auto\n                ms-md-auto\n                mr-md-12\n              ",attrs:{"elevation":0,"color":"#2196f3","small":"","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t("saveChanges"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }