<template>
  <div class="tank-flexbox pa-5">
    <measurement-level
      :percentage="tank.measuredVolumePercentage || 0"
      :color="tank.fluidColor"
      :isLargeComponent="true"
      class="mr-2"
      extended
    />
    <div class="tank-info">
      <div class="tank-info-group">
        <p class="tank-info-description">
          {{ $t("tankData.measuredVolume") }}:
        </p>
        <p class="font-weight-bold tank-info-value">
          {{
            tank.measuredVolume
              ? `${tank.measuredVolume} ${$t("measurementUnits.liters")}`
              : "-"
          }}
        </p>
      </div>
      <v-divider class="mb-3" />
      <div class="tank-info-group">
        <p class="tank-info-description">
          {{ $t("tankData.measuredVolumePercentage") }}:
        </p>
        <p class="font-weight-bold tank-info-value">
          {{
            tank.measuredVolumePercentage
              ? `${tank.measuredVolumePercentage} ${$t(
                  "measurementUnits.percentage"
                )}`
              : "-"
          }}
        </p>
      </div>
      <v-divider class="mb-3" />
      <div class="tank-info-group">
        <p class="tank-info-description">{{ $t("tankData.volume") }}:</p>
        <p class="font-weight-bold tank-info-value">
          {{ tank.volume }} {{ $t("measurementUnits.liters") }}
        </p>
      </div>
      <v-divider class="mb-3" />
      <div class="tank-info-group">
        <p class="tank-info-description">{{ $t("tankData.sensorAddress") }}:</p>
        <p class="font-weight-bold tank-info-value">
          {{ tank.sensorAddress }}
        </p>
      </div>
      <v-divider class="mb-3" />
      <div class="tank-info-group">
        <p class="tank-info-description">{{ $t("tankData.measuredIn") }}:</p>
        <p class="font-weight-bold tank-info-value">
          {{ tank.measuredIn ? $d(new Date(tank.measuredIn), "long") : "-" }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import MeasurementLevel from "@/components/tanks/MeasurementLevel.vue";

export default {
  name: "LastMeasurement",
  components: {
    MeasurementLevel,
  },
  props: {
    tank: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.tank-flexbox {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100%;
  flex-grow: 2;
}

.tank-info {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  width: 60%;
}

.tank-info-value {
  text-align: right;
}

.tank-info-group {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 960px) {
  .tank-info {
    width: 100%;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 600) {
  .tank-info {
    width: 100%;
    margin-top: 2rem;
  }
}
</style>