<template>
  <div class="box">
    <div class="top"></div>
    <div class="content">
      <div class="left" :style="{ 
        backgroundImage: `linear-gradient(to top, ${color} ${percentage}%, gray ${percentage}%)`,
        height: isLargeComponent ? '7rem' : '4rem',
        width: isLargeComponent ? '3rem' : '1rem'
      }"></div>
      <div class="container" :style="{
        backgroundImage: `linear-gradient(to top, ${color} ${percentage}%, gray ${percentage}%)`,
        height: isLargeComponent ? '7rem' : '4rem',
        width: isLargeComponent ? '11rem' : '7rem'
      }"></div>
      <div class="right" :style="{
        backgroundImage: `linear-gradient(to top, ${color} ${percentage}%, gray ${percentage}%)`,
        height: isLargeComponent ? '7rem' : '4rem',
        width: isLargeComponent ? '3rem' : '1rem'
      }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MeasurementLevel",
  props: {
    percentage: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    extended: {
      type: Boolean,
      default: false,
    },
    isLargeComponent: {
      type: Boolean,
      default: false
    }
  },
};
</script>
<style scoped>
.box {
	padding: 2rem;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	align-items: center;
}
.content {
	display: flex
}
.container {
  position: relative;
	width: 7rem;
	height: 4rem;
}
.right {
  position: relative;
	margin-left: 2px;
	width: 1rem;
	height: 4rem;
	border-radius: 0 100% 100% 0
}
.left {
  position: relative;
	margin-right: 2px;
	width: 1rem;
	height: 4rem;
	border-radius: 100% 0% 0% 100%
}
.top {
	width: 2rem;
	height: .6rem;
	background-color: gray;
	border-radius: 100% 100% 0% 0%;
}
</style>