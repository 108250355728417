<template>
  <v-col lg="4" md="6" cols="12" class="mb-2">
    <v-card
      class="card-shadow border-radius-xl"
      :style="{ borderLeft: `6px solid ${tank.fluidColor} !important` }"
      @click="redirectToTankInfo"
    >
      <div class="px-4 box-card">
        <div class="ms-4 my-auto d-flex align-center">
          <p class="text-typo text-h5 text-sm font-weight-bold mb-0">
            {{ tank.name }}:
            <span class="text-h6 text-secondary font-weight-normal ml-2">
              {{ $t(`tankData.fluidTypes.${tank.fluidType}`) }}</span
            >
          </p>
          <v-menu
            transition="slide-y-transition"
            offset-y
            offset-x
            min-width="150"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :ripple="false"
                class="text-secondary ms-auto"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon size="16">fas fa-ellipsis-v</v-icon>
              </v-btn>
            </template>

            <v-list class="pa-2">
              <v-list-item
                class="list-item-hover-active border-radius-lg"
                :to="`/tankedit/${this.tank.id}`"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="ls-0 text-body font-weight-600 mb-0"
                  >
                    {{ $t("edit") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="list-item-hover-active border-radius-lg"
                @click="showWarningAlert"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="ls-0 text-body font-weight-600 mb-0"
                  >
                    {{ $t("delete") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <hr class="horizontal dark" />
            </v-list>
          </v-menu>
        </div>
        <div class="d-flex flex-column">
          <div class="bomb d-flex flex-sm-row align-center justify-center">
            <measurement-level
              v-if="tank.measuredIn"
              :percentage="tank.measuredVolumePercentage"
              :color="tank.fluidColor"
            />
            <measurement-level
              v-else
              :percentage="0"
              :color="tank.fluidColor"
            />
            <v-col cols="6" class="text-left">
              <h4 class="text-h4 text-typo mb-0">
                {{ tank.measuredVolume }} {{ $t("measurementUnits.liters") }}
              </h4>
            </v-col>
          </div>
          <div class="d-flex flex-column">
            <!-- <hr class="horizontal dark mb-3" /> -->
            <v-row v-if="tank.measuredIn">
              <v-col cols="6">
                <h6 class="text-sm font-weight-bold text-typo mb-0">
                  {{ tank.measuredVolumePercentage
                  }}{{ $t("measurementUnits.percentage") }}
                  </h6>
                <p class="text-secondary text-sm font-weight-normal mb-0">
                  {{ $t("tankData.measuredVolumePercentage") }}
                </p>
              </v-col>
              <v-col cols="6" class="text-end">
                <h6 class="text-sm font-weight-bold text-typo mb-0">
                  {{tank.measuredIn ? $d(new Date(tank.measuredIn),"long"):"-"}}
                </h6>
                <p class="text-secondary text-sm font-weight-normal mb-0">
                  {{ $t("tankData.measuredIn") }}
                </p>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <p
                  class="text-secondary text-sm font-weight-normal mb-3"
                  style="margin-top: 9px"
                >
                  {{ $t("tankData.noMeasurements") }}
                </p>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-card>
  </v-col>
</template>
<script>
import MeasurementLevel from "@/components/tanks/MeasurementLevel.vue";
import Vue from "vue";
import { mapActions } from "vuex";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);
export default {
  name: "TankCard",
  props: {
    tank: {
      type: Object,
      required: true,
    },
  },
  components: {
    MeasurementLevel,
  },
  methods: {
    ...mapActions(["setError", "setSuccess"]),
    async showWarningAlert() {
      await this.$swal({
        title: this.$t("confirm"),
        text: this.$t("tankData.confirmDelete"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          try {
            this.$api.tank.deleteTank(this.tank.id);
            this.setSuccess(this.$t("tankData.deleteSuccess"));
            this.$destroy();
            this.$el.parentElement.removeChild(this.$el);
          } catch (error) {
            this.setError(error);
          }
        }
      });
    },
    redirectToTankInfo() {
      this.$router.push(`/tankinfo/${this.tank.id}`);
    },
  },
};
</script>

<style scoped>
  .box-card {
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
</style>
