<template>
  <v-container fluid class="py-6">
    <tank-form :id="$route.params.id"></tank-form>
  </v-container>
</template>
<script>
import TankForm from "@/components/tanks/TankForm.vue";

export default {
  name: "TankEdit",
  components: {
    TankForm,
  },
};
</script>
