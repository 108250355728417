<template>
  <div v-if="!loading">
    <v-card class="card-shadow border-radius-xl mx-6">
      <div class="card-header-padding">
        <div class="d-flex align-center">
          <div>
            <h5 class="font-weight-bold text-h5 text-typo mb-0">
              {{ $t("usersList") }}
            </h5>
          </div>

          <v-dialog max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :elevation="0"
                color="#2196f3"
                class="font-weight-bold text-white py-5 px-5 my-auto ms-auto"
                small
                v-bind="attrs"
                v-on="on"
                :ripple="false"
                height="43"
                @click="redirectToNewUser"
              >
                <v-icon class="material-icons-round me-3">add</v-icon>
                {{ $t("add") }}</v-btn
              >
            </template>
          </v-dialog>
        </div>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="filteredUsers"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80" class="border-radius-xl">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    :placeholder="$t('search')"
                  >
                    <template slot="prepend-inner">
                      <v-icon
                        color="#adb5bd"
                        size="18px"
                        class="material-icons-round mt-1"
                        >search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>
                <div class="d-flex align-center ms-2">
                  <span class="text-sm font-weight-normal text-body">
                    {{ item.firstName }} {{ item.lastName }}
                  </span>
                </div>
              </td>
              <td>
                <div class="d-flex justify-end">
                  <v-btn
                    icon
                    elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class="btn-ls me-2 my-2 rounded-sm"
                    color="#67748e"
                    @click="redirectToEditUser(item.id)"
                  >
                    <v-icon size="14" class="material-icons-round"
                      >construction</v-icon
                    >
                  </v-btn>

                  <v-btn
                    @click="showWarningAlert(item.id)"
                    icon
                    elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class="btn-ls me-2 my-2 rounded-sm"
                    color="#67748e"
                  >
                    <v-icon size="14" class="material-icons-round"
                      >delete</v-icon
                    >
                  </v-btn>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-sm"
              >{{ $t("itemsPerPage") }}:</span
            >
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10) || 1"
              :placeholder="$t('itemsPerPage')"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="rgb(33, 150, 243)"
              v-model="page"
              :length="pageCount || 0"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
  <loading v-else />
</template>
<script>
import Loading from "@/components/Loading.vue";
import { mapActions } from "vuex";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);
export default {
  name: "user",
  components: {
    Loading,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      users: [],
      search: "",
      loading: true,
      headers: [
        {
          text: this.$t("name"),
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "name",
          class:
            "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["setError", "setSuccess"]),
    async showWarningAlert(id) {
      this.Loading = true;
      await this.$swal({
        title: this.$t("confirm"),
        text: this.$t("confirmDeleteUser"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          try {
            this.$api.user.deleteUser(id);
            this.setSuccess(this.$t("successDeletedUser"));
            window.location.reload();
          } catch (error) {
            this.Loading = false;
            this.setError(error);
          }
        }
      });
    },
    async fetchUsers() {
      try {
        const response = await this.$api.user.getUsers();
        this.users = response.data;
        this.loading = false;
      } catch (error) {
        this.setError(error);
      }
    },
    redirectToNewUser() {
      this.$router.push("/user/new");
    },
    redirectToEditUser(id) {
      this.$router.push(`/useredit/${id}`);
    },
  },
  computed: {
    filteredUsers() {
      if (this.search == "") {
        return this.users;
      } else {
        const usersFiltered = this.users.filter((user) =>
          `${user.firstName} ${user.lastName}`
            .toLowerCase()
            .includes(this.search.toLowerCase())
        );
        return usersFiltered;
      }
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
  async mounted() {
    await this.fetchUsers();
  },
};
</script>
