<template>
  <div class="shifts-container">
    <v-card
      class="card-shadow border-radius-xl mx-6 py-5 px-6"
      :loading="loading"
    >
      <h5 class="font-weight-bold text-h5 text-typo mb-0 py-1">
        {{ $t("installation") }}
      </h5>
      <validation-observer ref="selectInstallation">
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="installation"
            rules="required"
          >
            <v-select
              v-model="installation.id"
              :items="installations"
              item-text="name"
              item-value="id"
              :label="$t('selectInstallation')"
              :error-messages="errors"
              :disabled="loading"
              @input="fetchShifts"
              class="font-size-input input-style"
              height="36"
              required
              single-line
            />
          </validation-provider>
        </v-form>
      </validation-observer>
    </v-card>

    <v-card
      v-if="installation.id"
      class="card-shadow border-radius-xl mx-6 py-5 px-6 mt-10 mb-5"
    >
      <h5 class="font-weight-bold text-h5 text-typo mb-0">
        {{ $t("shifts") }}
      </h5>
      <validation-observer ref="shifts">
        <v-form
          v-model="valid"
          v-for="(shift, index) in installation.shifts"
          :key="index"
          class="shift-form"
        >
          <validation-provider
            v-slot="{ errors }"
            name="shiftName"
            :rules="shiftClosingTimeValidation"
            class="w-100"
          >
            <v-text-field
              v-model="installation.shifts[index].name"
              :label="$t('shiftsData.name')"
              :error-messages="errors"
              class="font-size-input input-style"
              required
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="shiftClosingTime"
            rules="required"
            class="w-100"
          >
            <v-text-field
              v-model="installation.shifts[index].closingTime"
              class="font-size-input input-style ml-4"
              :disabled="loading"
              :label="$t('shiftsData.closingtime')"
              :error-messages="errors"
              type="time"
              required
            />
          </validation-provider>
          <v-btn
            icon
            elevation="0"
            :ripple="false"
            height="28"
            min-width="36"
            width="36"
            class="btn-ls me-2 mb-2 rounded-sm"
            color="#67748e"
            @click="deleteShift(index)"
          >
            <v-icon size="20" class="material-icons-round">delete</v-icon>
          </v-btn>
        </v-form>
      </validation-observer>
      <empty-state
        v-if="!installation.shifts || installation.shifts.length === 0"
      />

      <v-card-actions class="card-buttons">
        <v-spacer></v-spacer>
        <v-btn
          :elevation="0"
          class="
            font-weight-bold
            py-5
            px-5
            my-auto
            ms-auto
            mr-2
            secondary-button
            v-btn
          "
          small
          :ripple="false"
          height="43"
          :loading="loading"
          @click="addShift"
        >
          <v-icon class="material-icons-round me-3">add</v-icon>
          {{ $t("shiftsData.addShift") }}</v-btn
        >

        <v-btn
          v-if="installation.shifts && installation.shifts.length > 0"
          :elevation="0"
          color="#2196f3"
          class="
            font-weight-bold
            text-white
            py-5
            px-5
            my-auto
            ms-auto
            mr-2
            v-btn
          "
          small
          :ripple="false"
          height="43"
          :loading="loading"
          @click="handleSubmit"
          :disabled="!valid"
        >
          {{ $t("saveChanges") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import EmptyState from "@/components/EmptyState.vue";
import { mapActions } from "vuex";

export default {
  name: "Shifts",
  components: {
    EmptyState,
  },
  data: () => ({
    installations: [],
    installation: {
      id: null,
      shifts: [],
    },
    loading: true,
    enableSubmit: false,
    valid: false,
  }),
  computed: {
    shiftClosingTimeValidation() {
      return {
        required: {},
        max: {
          length: 100,
        },
      };
    },
    allShiftsAreValid: (vm) =>
      vm.allShiftsHaveDifferentStartAndEndTime && !vm.areThereShiftsOverlapped,
    allShiftsHaveDifferentStartAndEndTime: (vm) =>
      vm.installation.shifts.every(
        (shift) => shift.startTime !== shift.endTime
      ),
    areThereShiftsOverlapped: (vm) => {
      let areThereShiftsOverlapped = false;

      vm.installation.shifts?.forEach((shift) => {
        vm.installation.shifts
          .filter(
            (shiftToCompare) =>
              JSON.stringify(shift) !== JSON.stringify(shiftToCompare)
          )
          .forEach((shiftToCompare) => {
            const shiftStartTime = new Date();

            shiftStartTime.setHours(shift.startTime?.split(":")[0]);
            shiftStartTime.setMinutes(shift.startTime?.split(":")[1]);

            const shiftToCompareStartTime = new Date();

            shiftToCompareStartTime.setHours(
              shiftToCompare.startTime?.split(":")[0]
            );
            shiftToCompareStartTime.setMinutes(
              shiftToCompare.startTime?.split(":")[1]
            );

            const shiftToCompareEndTime = new Date();

            shiftToCompareEndTime.setHours(
              shiftToCompare.endTime?.split(":")[0]
            );
            shiftToCompareEndTime.setMinutes(
              shiftToCompare.endTime?.split(":")[1]
            );

            if (
              shiftStartTime >= shiftToCompareStartTime &&
              shiftStartTime <= shiftToCompareEndTime
            ) {
              areThereShiftsOverlapped = true;
            }

            if (shiftToCompareStartTime > shiftToCompareEndTime) {
              const midnight = new Date();

              midnight.setHours(23);
              midnight.setMinutes(59);

              if (
                shiftStartTime >= shiftToCompareStartTime &&
                shiftStartTime <= midnight
              ) {
                areThereShiftsOverlapped = true;
              }

              midnight.setHours(0);
              midnight.setMinutes(0);

              if (
                shiftStartTime >= midnight &&
                shiftStartTime <= shiftToCompareEndTime
              ) {
                areThereShiftsOverlapped = true;
              }
            }
          });
      });

      return areThereShiftsOverlapped;
    },
  },
  methods: {
    ...mapActions(["setError", "setSuccess"]),
    async handleSubmit() {
      this.valid = await this.$refs.shifts.validate();

      if (!this.valid) {
        return;
      }

      this.loading = true;
      this.updateShifts();
    },
    async updateShifts() {
      try {
        await this.$api.shiftWork.putShifts(
          this.installation.id,
          this.installation
        );
        this.setSuccess(this.$t("shiftsData.success"));
        this.loading = false;
      } catch (error) {
        this.setError(error);
        this.loading = false;
      }
    },
    async fetchInstallations() {
      this.loading = true;
      this.installation.id = null;
      try {
        const response = await this.$api.shiftWork.getInstallations();
        this.installations = response.data;
        this.loading = false;
      } catch (error) {
        this.setError(error);
      }
    },
    fetchShifts() {
      this.installation.shifts = this.installations.find(
        (i) => i.id == this.installation.id
      ).shifts;
    },
    addShift() {
      if (!this.installation.shifts) {
        this.installation.shifts = [];
      }

      this.installation.shifts.push({
        id: null,
        name: null,
        closingTime: null,
      });
    },
    deleteShift(index) {
      this.installation.shifts.splice(index, 1);
    },
  },
  async mounted() {
    await this.fetchInstallations();
  },
};
</script>

<style scoped>
.shifts-container >>> .v-responsive {
  display: block;
  flex: unset;
}

.shift-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shift-icon {
  justify-self: flex-start;
}

.card-buttons {
  display: flex;
}

.secondary-button {
  color: #2196f3;
  border: 2px solid #2196f3;
  background-color: #fff !important;
}

@media screen and (max-width: 600px) {
  .card-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-buttons >>> .v-btn {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 20px !important;
  }
}
</style>
