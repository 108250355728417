<template>
  <div class="card-shadow border-radius-xl white">
    <div class="report pa-5" v-if="!loading">
      <header class="report-header">
        <v-img src="@/assets/img/logo.png" contain class="logo mx-auto" />

        <h3 class="report-title text-center">
          {{ $t("report.shiftsEndsReport") }}
        </h3>

        <div class="report-info mx-auto my-5 pa-5 border-radius-xl">
          <div class="report-info-item">
            <span class="report-info-label">
              {{ $t("report.initialDateTime") }}:
            </span>
            <span class="report-info-value">
              {{ $d(new Date(initialDateTime), "long") }}
            </span>
          </div>

          <div class="report-info-item">
            <span class="report-info-label">
              {{ $t("report.finalDateTime") }}:
            </span>
            <span class="report-info-value">
              {{ $d(new Date(finalDateTime), "long") }}
            </span>
          </div>
        </div>
      </header>

      <shifts-chart :shifts="shiftsChartData" />
      <v-divider />
      <shifts-table :dataPage="shiftsTableData" />
    </div>
    <loading class="py-15" v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/Loading.vue";
import ShiftsChart from "@/components/shifts/ShiftsChart.vue";
import ShiftsTable from "@/components/shifts/ShiftsTable.vue";

export default {
  name: "ShiftsReport",
  components: {
    Loading,
    ShiftsChart,
    ShiftsTable,
  },
  props: {
    initialDateTime: {
      type: String,
      required: true,
    },
    finalDateTime: {
      type: String,
      required: true,
    },
    tankId: {
      type: String,
      default: null,
    },
    companyId: {
      type: String,
      default: null,
    },
    shiftId: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    shiftsChartData: [],
    shiftsTableData: {},
  }),
  computed: {
    ...mapGetters(["page", "pageSize"]),
  },
  methods: {
    ...mapActions([
      "setError",
      "setPage",
      "setPageSize",
      "setRegistersTotal",
      "setData",
      "setLoadingDataTable",
    ]),
    async fetchShiftsChartData() {
      this.loading = true;
      try {
        const response = await this.$api.shiftWork.shiftWorksChart(
          this.initialDateTime,
          this.finalDateTime,
          this.tankId,
          this.companyId,
          this.shiftId
        );
        this.shiftsChartData = response.data;
        this.loading = false;
      } catch (error) {
        this.setError(error);
        this.loading = false;
      }
    },
    async fetchShiftsTableData() {
      this.setLoadingDataTable(true);
      try {
        const response = await this.$api.shiftWork.shiftWorksTable(
          this.initialDateTime,
          this.finalDateTime,
          this.tankId,
          this.companyId,
          this.shiftId
        );

        const { page, pageSize, registersTotal, data } = response.data;
        this.setPage(page);
        this.setPageSize(pageSize);
        this.setRegistersTotal(registersTotal);
        this.setData(data);

        this.shiftsTableData = {
          ...response.data,
          data: data.map((item) => {
            return {
              ...item,
              measuredIn: this.$d(new Date(item.measuredIn), "long"),
            };
          }),
        };

        this.setLoadingDataTable(false);
      } catch (error) {
        this.setError(error);
        this.setLoadingDataTable(false);
      }
    },
  },
  mounted() {
    this.fetchShiftsChartData();
    this.fetchShiftsTableData();
  },
  watch: {
    page() {
      this.fetchShiftsTableData();
    },
    pageSize() {
      this.fetchShiftsTableData();
    },
  },
};
</script>

<style scoped>
.logo {
  width: 50%;
  max-width: 350px;
  margin-top: 50px;
}

.report-info {
  width: 90%;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  border: 1px solid #9e9e9e;
}

.report-info-item {
  padding: 0.5rem 0;
}

.report-info-label {
  font-weight: bold;
}
</style>
