export default [
  "RegularGasoline",
  "AdditiveGasoline",
  "PremiumGasoline",
  "FormulatedGasoline",
  "Ethanol",
  "EthanolAdditive",
  "Diesel",
  "DieselS10",
  "DieselAdditive",
  "PremiumDiesel",
];
