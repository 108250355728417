<template>
  <div class="card-shadow border-radius-xl white">
    <div class="report pa-5" v-if="!loading">
      <header class="report-header">
        <v-img src="@/assets/img/logo.png" contain class="logo mx-auto" />

        <h3 class="report-title text-center">
          {{ $t("report.dataHistoryReport") }}
        </h3>

        <div class="report-info mx-auto my-5 pa-5 border-radius-xl">
          <h3 class="report-info-item">
            <span class="report-info-label"> {{ $t("tank") }}: </span>
            <span class="report-info-value font-weight-bold">
              {{ tankName }}
            </span>
          </h3>

          <v-divider />

          <div class="report-info-item">
            <span class="report-info-label">
              {{ $t("tankData.fluidType") }}:
            </span>
            <span class="report-info-value">
              {{ $t(`tankData.fluidTypes.${fluidType}`) }}
            </span>
          </div>

          <div class="report-info-item">
            <span class="report-info-label">
              {{ $t("tankData.sensorAddress") }}:
            </span>
            <span class="report-info-value">
              {{ sensorAddress }}
            </span>
          </div>

          <v-divider />

          <div class="report-info-item">
            <span class="report-info-label">
              {{ $t("report.initialDateTime") }}:
            </span>
            <span class="report-info-value">
              {{ $d(new Date(initialDateTime), "long") }}
            </span>
          </div>

          <div class="report-info-item">
            <span class="report-info-label">
              {{ $t("report.finalDateTime") }}:
            </span>
            <span class="report-info-value">
              {{ $d(new Date(finalDateTime), "long") }}
            </span>
          </div>
        </div>
      </header>
      <measurements-chart :measurements="measurements" class="my-2" />
      <v-divider />
      <measurements-table
        :measurements="measurementsWithFormattedDates"
        class="my-2"
      />
    </div>
    <loading class="py-15" v-else />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Loading from "@/components/Loading.vue";
import MeasurementsChart from "@/components/tanks/MeasurementsChart.vue";
import MeasurementsTable from "@/components/tanks/MeasurementsTable.vue";

export default {
  name: "Report",
  components: {
    Loading,
    MeasurementsChart,
    MeasurementsTable,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    initialDateTime: {
      type: String,
      required: true,
    },
    finalDateTime: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    measurements: [],
    tank: null,
  }),
  computed: {
    tankName: (vm) => vm.measurements[0]?.name || vm.tank?.name,
    sensorAddress: (vm) => vm.measurements[0]?.sensorAddress || "",
    fluidType: (vm) => vm.measurements[0]?.fluidType || vm.tank.fluidType,
    measurementsWithFormattedDates: (vm) =>
      vm.measurements.map((measurement) => ({
        ...measurement,
        measuredIn: vm.$d(new Date(measurement.measuredIn), "long"),
      })),
  },
  methods: {
    ...mapActions(["setError"]),
    async fetchTankMeasurements() {
      this.loading = true;

      try {
        const response = await this.$api.tank.getTankMeasurementsOverPeriod(
          this.id,
          this.initialDateTime,
          this.finalDateTime
        );
        this.measurements = response.data;
        if (this.measurements.length === 0) {
          await this.fetchTankWithoutMeasurements();
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.setError(error);
      }
    },
    async fetchTankWithoutMeasurements() {
      this.loading = true;

      try {
        const response = await this.$api.tank.getTank(this.id);
        this.tank = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.setError(error);
        this.$router.push("/tank");
      }
    },
  },
  created() {
    this.fetchTankMeasurements();
  },
};
</script>

<style scoped>
.logo {
  width: 50%;
  max-width: 350px;
  margin-top: 50px;
}

.report-info {
  width: 90%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;

  border: 1px solid #9e9e9e;
}

.report-info-item {
  padding: 0.5rem 0;
}

.report-info-label {
  font-weight: bold;
}
</style>
