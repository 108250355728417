<template>
  <v-card
    class="card-shadow border-radius-xl mt-6"
    id="basic"
    :loading="loading"
  >
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">{{ $t("tankInfo") }}</h5>
    </div>
    <validation-observer ref="form">
      <v-form
        v-model="valid"
        ref="form"
        :lazy-validation="true"
        class="px-6 pb-6 pt-0"
      >
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0 col-sm-6">
            <label class="text-sm text-body">Sensor</label>
            <validation-provider
              v-slot="{ errors }"
              name="tank"
              rules="required"
            >
              <v-select
                v-model="tank.sensorId"
                :items="sensorsList"
                item-text="name"
                :item-value="'id'"
                :label="$t('sensor')"
                :error-messages="errors"
                class="font-size-input input-style"
                single-line
                required
                height="36"
              >
              </v-select>
            </validation-provider>
          </v-col>
          <v-col cols="12" class="pb-0 col-sm-6">
            <label class="text-sm text-body">{{
              $t("tankData.fluidType")
            }}</label>
            <validation-provider
              v-slot="{ errors }"
              name="fluidType"
              rules="required"
            >
              <v-select
                v-model="tank.fluidType"
                :items="fluidTypes"
                item-text="text"
                item-value="value"
                :label="$t('tankData.fluidType')"
                :error-messages="errors"
                required
                class="font-size-input input-style"
                single-line
                height="36"
              >
              </v-select>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="name"
              rules="required"
            >
              <v-text-field
                v-model="tank.name"
                :label="$t('tankData.name')"
                :error-messages="errors"
                required
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="volume"
              rules="required"
            >
              <v-text-field
                v-model="tank.volume"
                :label="`${$t('tankData.volume')} (${$t(
                  'measurementUnits.liters'
                )})`"
                :error-messages="errors"
                required
                type="number"
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="diameter"
              rules="required"
            >
              <v-text-field
                v-model="tank.diameter"
                :label="`${$t('tankData.diameter')} (${$t(
                  'measurementUnits.millimeters'
                )})`"
                :error-messages="errors"
                required
                type="number"
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="length"
              rules="required"
            >
              <v-text-field
                v-model="tank.length"
                :label="`${$t('tankData.length')} (${$t(
                  'measurementUnits.millimeters'
                )})`"
                :error-messages="errors"
                required
                type="number"
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="sensorInstallationHeight"
              rules="required"
            >
              <v-text-field
                v-model="tank.sensorInstallationHeight"
                :label="`${$t('tankData.sensorInstallationHeight')} (${$t(
                  'measurementUnits.millimeters'
                )})`"
                :error-messages="errors"
                required
                type="number"
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="fluidHeight"
              rules="required"
            >
              <v-text-field
                v-model="tank.fluidHeight"
                :label="`${$t('tankData.fluidHeight')} (${$t(
                  'measurementUnits.millimeters'
                )})`"
                :error-messages="errors"
                required
                type="number"
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0 col-sm-6">
            <label class="text-sm text-body">{{
              $t("tankData.fluidColor")
            }}</label>
            <v-color-picker
              v-model="tank.fluidColor"
              dot-size="25"
              hide-inputs
              hide-canvas
              width="100%"
            />
          </v-col>
          <v-col
            cols="12"
            class="pb-0 col-sm-6 d-flex justify-center align-center"
          >
            <v-card-actions>
              <v-btn
                :elevation="0"
                color="#2196f3"
                class="
                  font-weight-bold
                  text-white
                  py-5
                  px-12
                  my-auto
                  ms-md-auto
                  mr-md-12
                "
                small
                @click="handleSubmit"
                :loading="loading"
                :disabled="!valid"
              >
                {{ $t("saveChanges") }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import sensorsMixin from "@/mixins/sensorsMixin";
import fluidTypes from "@/data/constants/fluidTypes";
export default {
  name: "tank-form",
  mixins: [sensorsMixin],
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      tank: {
        name: null,
        fluidType: null,
        fluidColor: null,
        volume: null,
        diameter: null,
        length: null,
        sensorInstallationHeight: null,
        fluidHeight: null,
        sensorId: null,
      },
    };
  },
  computed: {
    fluidTypes() {
      return fluidTypes.map((type) => ({
        value: type,
        text: this.$t(`tankData.fluidTypes.${type}`),
      }));
    },
    hexColor() {
      return this.tank.fluidColor?.hex
        ? this.tank.fluidColor.hex
        : this.tank.fluidColor;
    },
    sensorsList() {
      return this.sensors.map((sensor) => ({
        ...sensor,
        disabled: sensor.installedOn
          ? this.id
            ? sensor.installedOn.id != this.id
            : true
          : false,
      }));
    },
    requestBody() {
      return {
        name: this.tank.name,
        fluidType: this.tank.fluidType,
        sensorId: this.tank.sensorId,
        fluidColor: this.hexColor,
        diameter: Number(this.tank.diameter),
        length: Number(this.tank.length),
        sensorInstallationHeight: Number(this.tank.sensorInstallationHeight),
        fluidHeight: Number(this.tank.fluidHeight),
        volume: Number(this.tank.volume),
        stateRegister: "Active",
      };
    },
  },
  methods: {
    ...mapActions(["setError", "setSuccess"]),
    async handleSubmit() {
      this.valid = await this.$refs.form.validate();

      if (!this.valid) return;

      this.loading = true;

      if (this.id) {
        await this.updateTank();
        return;
      }

      await this.createTank();
    },
    async createTank() {
      try {
        await this.$api.tank.postTank(this.requestBody);
        this.loading = false;
        this.setSuccess(this.$t("tankData.success"));
        this.$router.push("/tank");
      } catch (error) {
        this.setError(error);
        this.loading = false;
      }
    },
    async updateTank() {
      try {
        await this.$api.tank.putTank(this.id, this.requestBody);
        this.loading = false;
        this.setSuccess(this.$t("tankData.success"));
        this.$router.push("/tank");
      } catch (error) {
        this.setError(error);
        this.loading = false;
      }
    },
    async fetchTank() {
      this.loading = true;

      try {
        const response = await this.$api.tank.getTank(this.id);
        this.tank = {
          ...response.data,
          sensorId: response.data.sensor.id?.toString(),
        };
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.setError(error);
        this.$router.push("/tank");
      }
    },
  },
  mounted() {
    this.fetchSensors();
    if (this.id) {
      this.fetchTank();
    }
  },
};
</script>
