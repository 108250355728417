<template>
  <div class="loading-container">
    <v-progress-circular
      :size="40"
      :width="4"
      indeterminate
      :color="$vuetify.theme.themes.light.primary"
    />
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style scoped>
.loading-container {
  height: 80% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
