<template>
  <v-col lg="4" md="6" cols="12" class="mb-3">
    <v-card
      class="card-shadow border-radius-xl"
      :style="{ borderLeft: `6px solid rgb(54, 211, 154) !important` }"
    >
      <div class="px-4 py-3">
        <div class="d-flex">
          <v-avatar
            size="74"
            class="border-radius-xl bg-gradient-default pa-2 mt-n6"
          >
            <v-img
              :src="require(`@/assets/icons/sensor.svg`)"
              width="40"
              height="40"
            >
            </v-img>
          </v-avatar>
          <div class="ms-4 my-auto">
            <h6 class="text-h6 text-typo font-weight-bold mb-0">
              {{ sensor.name }}
            </h6>
          </div>
          <v-menu
            transition="slide-y-transition"
            offset-y
            offset-x
            min-width="150"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :ripple="false"
                class="text-secondary ms-auto mt-3"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon size="16">fas fa-ellipsis-v</v-icon>
              </v-btn>
            </template>

            <v-list class="pa-2">
              <v-list-item
                class="list-item-hover-active border-radius-lg"
                :to="`/sensoredit/${sensor.id}`"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="ls-0 text-body font-weight-600 mb-0"
                  >
                    {{ $t("edit") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                class="list-item-hover-active border-radius-lg"
                @click="showWarningAlert(sensor.id)"
              >
                <v-list-item-content class="pa-0">
                  <v-list-item-title
                    class="ls-0 text-body font-weight-600 mb-0"
                  >
                    {{ $t("delete") }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <hr class="horizontal dark" />
            </v-list>
          </v-menu>
        </div>
        <hr class="horizontal dark mb-3" />
        <v-row class="d-flex flex-column">
          <v-col cols="12" class="d-flex w-100 pb-0">
            <p class="text-secondary text-sm font-weight-normal mb-0">
              {{ $t("sensorData.address") }}:
            </p>
            <h6 class="text-sm font-weight-bold text-typo mb-0 ml-2">
              {{ sensor.address }}
            </h6>
          </v-col>
          <v-col cols="12" class="d-flex w-100 pb-0 pt-2">
            <p class="text-secondary text-sm font-weight-normal mb-0">
              {{ $t("sensorData.description") }}:
            </p>
            <h6 class="text-sm font-weight-bold text-typo mb-0 ml-2">
              {{ sensor.description }}
            </h6>
          </v-col>
          <v-col cols="12" class="d-flex w-100 pt-2" v-if="sensor.installedOn">
            <p class="text-secondary text-sm font-weight-normal mb-0">
              {{ $t("sensorData.installedOn") }}:
            </p>
            <h6 class="text-sm font-weight-bold text-typo mb-0 ml-2">
              {{ sensor.installedOn.name }}
            </h6>
          </v-col>
          <v-col cols="12" class="d-flex w-100 pt-2" v-else>
            <h6 class="text-sm font-weight-bold text-typo mb-0">
              {{ $t("sensorData.notInstalled") }}
            </h6>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-col>
</template>
<script>
import { mapActions } from "vuex";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);
export default {
  name: "sensor-card",
  props: {
    sensor: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(["setSuccess", "setError"]),
    async showWarningAlert() {
      await this.$swal({
        title: this.$t("confirm"),
        text: this.$t("sensorData.deleteSensorMessage"),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          try {
            this.$api.sensor.deleteSensor(this.sensor.id);
            this.setSuccess(this.$t("sensorData.deleteSuccess"));
            this.$destroy();
            this.$el.parentElement.removeChild(this.$el);
          } catch (error) {
            this.setError(error);
          }
        }
      });
    },
  },
};
</script>
