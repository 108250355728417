<template>
  <div>
    <validation-observer>
      <label class="text-sm text-body">{{ $t("report.selectAShift") }}</label>
      <validation-provider v-slot="{ errors }" name="tank" rules="required">
        <v-select
          v-model="selectedShift"
          @input="handleInput"
          :items="shifts"
          item-text="name"
          item-value="id"
          :label="label"
          :rules="rules"
          :required="required"
          :error-messages="errors"
          :loading="loading"
          :disabled="loading || disabled"
          class="font-size-input input-style"
          single-line
          height="36"
        />
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ShiftSelectorInput",
  props: {
    value: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default() {
        return this.$t("report.selectAShift");
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    loading: false,
    shifts: [],
    selectedShift: null,
  }),
  methods: {
    ...mapActions(["setError"]),
    async fetchShifts() {
      this.loading = true;

      try {
        const response = await this.$api.shiftWork.getWorkShiftsFromCompany(
          this.companyId
        );
        this.shifts = response.data.shiftWorks;
        this.loading = false;
      } catch (error) {
        this.setError(error);
      }
    },
    handleInput() {
      this.$emit("input", this.selectedShift);
    },
  },
  watch: {
    companyId() {
      if (this.companyId) this.fetchShifts();
    },
  },
};
</script>

<style></style>
