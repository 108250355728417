<template>
  <v-row class="align-center d-flex h-100">
    <v-col lg="4" md="7" class="mx-auto">
      <v-card class="card-shadow border-radius-xl py-1">
        <validation-observer ref="formLogin">
          <v-form :disabled="loading" @submit.prevent="login">
            <div
              class="
                v-navigation-drawer-brand
                pa-5
                d-flex
                align-center
                justify-content-center
              "
            >
              <v-img src="@/assets/img/logo.png" class="navbar-brand-img">
              </v-img>
            </div>
            <div class="card-padding">
              <validation-provider
                v-slot="{ errors }"
                name="username"
                rules="required"
              >
                <v-text-field
                  v-model="username"
                  :label="$t('login.username')"
                  required
                  :error-messages="errors"
                  class="font-size-input input-style"
                  validate-on-blur
                />
              </validation-provider>
              <validation-provider
                v-slot="{ errors }"
                name="password"
                rules="required"
              >
                <v-text-field
                  v-model="password"
                  :label="$t('login.password')"
                  required
                  :error-messages="errors"
                  type="password"
                  class="font-size-input input-style"
                  validate-on-blur
                ></v-text-field>
              </validation-provider>
              <v-btn
                v-if="!loading"
                :disabled="!isInputDataValid || loading"
                block
                type="submit"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                  w-100
                "
                color="#061b23"
                style="color: white"
                small
              >
                {{ $t("login.logIn") }}
              </v-btn>
              <v-btn
                v-else
                block
                disabled
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                  py-2
                  px-6
                  me-2
                  mt-6
                  mb-2
                  w-100
                "
                color="#061b23"
                small
              >
                <v-progress-circular indeterminate />
              </v-btn>
            </div>
          </v-form>
        </validation-observer>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "login",
  data() {
    return {
      username: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions(["authenticateUser", "clearHistory", "setError"]),
    async login() {
      this.loading = true;
      try {
        if (await this.$refs.formLogin.validate()) {
          const requestBody = {
            username: this.username,
            password: this.password,
          };
          const response = await this.$api.auth.postAuth(requestBody);
          this.disabled = false;
          this.authenticateUser(response.data);
          this.$router.push("/tank");
        }
      } catch (error) {
        this.loading = false;
        this.disabled = false;
        this.setError(error);
      }
    },
  },
  computed: {
    isInputDataValid() {
      return this.username && this.password;
    },
  },
  mounted() {
    this.clearHistory();
  },
};
</script>
