<template>
  <v-chart
    v-if="measurements.length > 0"
    :option="option"
    class="chart"
    ref="chart"
  />
  <empty-state v-else />
</template>

<script>
import EmptyState from "@/components/EmptyState.vue";

export default {
  name: "MeasurementsChart",
  components: {
    EmptyState,
  },
  props: {
    measurements: {
      type: Array,
      required: true,
    },
  },
  computed: {
    option() {
      return {
        xAxis: {
          type: "time",
          splitLine: {
            lineStyle: {
              color: ["#BDBDBD", "#BFBFBF"],
            },
            interval: 2,
          },
          axisLabel: {
            formatter: (value) =>
              ` ${this.$d(value, "short")} \n${new Date(
                value
              ).getHours()}:${new Date(value)
                .getMinutes()
                .toFixed(0)
                .padStart(2, "0")} `,
            hideOverlap: true,
            fontSize: 10,
            padding: [10, 300],
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: ["#BDBDBD", "#BFBFBF"],
            },
          },
          axisLabel: {
            formatter: (value) =>
              `${value} ${this.$t("measurementUnits.liters")}`,
          },
          scale: true,
        },
        series: [
          {
            data: this.measurements.map((m) => [
              new Date(m.measuredIn).getTime(),
              m.measuredVolume,
            ]),
            type: "line",
            smooth: true,
            itemStyle: {
              color: this.measurements[0]?.fluidColor,
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          formatter: (params) =>
            `<b>${this.$d(params[0].data[0], "long")}</b><br>${
              params[0].data[1]
            } ${this.$t("measurementUnits.liters")}`,
        },
      };
    },
  },
};
</script>

<style>
.chart {
  height: 400px;
  width: 100%;
}
</style>
