<template>
  <v-chart
    v-if="shifts.length > 0"
    :option="option"
    class="chart mt-10 mb-5"
    ref="chart"
  />
  <empty-state v-else />
</template>

<script>
import EmptyState from "@/components/EmptyState.vue";

export default {
  name: "ShiftsChart",
  components: {
    EmptyState,
  },
  props: {
    shifts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    option() {
      return {
        xAxis: {
          type: "time",
          splitLine: {
            lineStyle: {
              color: ["#BDBDBD", "#BFBFBF"],
            },
            interval: 2,
          },
          axisLabel: {
            formatter: (value) =>
              ` ${this.$d(value, "short")} \n${new Date(
                value
              ).getHours()}:${new Date(value)
                .getMinutes()
                .toFixed(0)
                .padStart(2, "0")} `,
            hideOverlap: true,
            fontSize: 10,
            padding: [10, 300],
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: ["#BDBDBD", "#BFBFBF"],
            },
          },
          axisLabel: {
            formatter: (value) =>
              `${value} ${this.$t("measurementUnits.liters")}`,
          },
          scale: true,
        },
        series: this.shifts.map((shift) => ({
          data: shift.values.map((value) => [
            new Date(value.measuredIn).getTime(),
            value.measuredVolume,
            shift.company,
            shift.tank.name,
            shift.tank.fluidColor,
          ]),
          type: "line",
          smooth: true,
          itemStyle: {
            color: shift.tank.fluidColor,
          },
          name: `${shift.company} - ${shift.tank.name}`,
        })),
        tooltip: {
          trigger: "item",
          formatter: (params) => this.getTooltipContent(params.data),
        },
        legend: {
          type: "scroll",
          data: this.shifts.map((shift) => ({
            name: `${shift.company} - ${shift.tank.name}`,
          })),
        },
      };
    },
  },
  methods: {
    getTooltipContent(data) {
      let tooltipContent = `<b>`;
      tooltipContent += `<span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: ${data[4]}; margin-right: 5px;"></span>`;
      tooltipContent += `${data[2]} - ${data[3]}</br>`;
      tooltipContent += `</b>`;
      tooltipContent += `${this.$d(data[0], "long")}</br>`;
      tooltipContent += `${data[1]} ${this.$t("measurementUnits.liters")}`;

      return tooltipContent;
    },
  },
  mounted() {},
};
</script>

<style>
.chart {
  height: 400px;
  width: 100%;
}
</style>
