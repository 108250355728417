<template>
  <v-data-table
    class="measurements-table"
    :headers="headers"
    :items="measurements"
    :disable-sort="$vuetify.breakpoint.xs"
    :no-data-text="$t('emptyState')"
    disable-pagination
    hide-default-footer
  />
</template>

<script>
export default {
  name: "MeasurementsTable",
  props: {
    measurements: {
      type: Array,
      required: true,
    },
  },
  computed: {
    headers: (vm) => [
      {
        text: `${vm.$t("tankData.measuredIn")}`,
        value: "measuredIn",
        sortable: true,
      },
      {
        text: `${vm.$t("tankData.measuredVolume")} (${vm.$t(
          "measurementUnits.liters"
        )})`,
        value: "measuredVolume",
        sortable: true,
      },
      {
        text: `${vm.$t("tankData.measuredVolumePercentage")} (${vm.$t(
          "measurementUnits.percentage"
        )})`,
        value: "measuredVolumePercentage",
        sortable: true,
      },
    ],
  },
};
</script>

<style></style>
