<template>
  <v-container fluid class="py-6">
    <user-form :id="$route.params.id"></user-form>
  </v-container>
</template>
<script>
import UserForm from "@/components/user/UserForm.vue";

export default {
  name: "UserEdit",
  components: {
    UserForm,
  },
};
</script>
