<template>
  <v-container fluid class="py-6">
    <sensor-form :id="$route.params.id"></sensor-form>
  </v-container>
</template>
<script>
import SensorForm from "@/components/sensors/SensorForm.vue";

export default {
  name: "SensorEdit",
  components: {
    SensorForm,
  },
};
</script>
