<template>
  <div class="pa-6">
    <shifts-report
      :initialDateTime="$route.params.initialDateTime"
      :finalDateTime="$route.params.finalDateTime"
      :tankId="$route.params.tankId"
      :companyId="$route.params.companyId"
      :shiftId="$route.params.shiftId"
    />
  </div>
</template>

<script>
import ShiftsReport from "@/components/reports/shifts/ShiftsReport.vue";

export default {
  name: "ShiftsEndsReport",
  components: {
    ShiftsReport,
  },
};
</script>

<style></style>
