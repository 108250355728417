<template>
  <div class="chart-container" v-if="!loading">
    <measurements-chart :measurements="measurements" />
  </div>
  <loading v-else />
</template>

<script>
import { mapActions } from "vuex";
import Loading from "@/components/Loading.vue";
import MeasurementsChart from "@/components/tanks/MeasurementsChart.vue";

export default {
  name: "LastEndsOfShifts",
  components: {
    Loading,
    MeasurementsChart,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: true,
    measurements: [],
  }),
  methods: {
    ...mapActions(["setError"]),
    async fetchTankLastEndsOfShifts() {
      this.loading = true;

      try {
        const response =
          await this.$api.tank.getTankLastEndsOfShiftsMeasurements(this.id);
        this.measurements = response.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.setError(error);
        this.$router.push("/tank");
      }
    },
  },
  mounted() {
    this.fetchTankLastEndsOfShifts();
  },
};
</script>

<style>
.chart-container {
  min-width: 100px;
  min-height: 15rem;
  overflow-x: auto;
}
</style>
