<template>
  <v-card
    class="card-shadow border-radius-xl mt-6"
    id="basic"
    :loading="loading"
  >
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">
        {{ $t("userForm.title") }}
      </h5>
    </div>
    <validation-observer ref="form">
      <v-form v-model="valid" :lazy-validation="true" class="px-6 pb-6 pt-0">
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="firstName"
              rules="required"
            >
              <v-text-field
                v-model="user.firstName"
                :label="$t('userForm.firstName')"
                :error-messages="errors"
                maxlength="100"
                required
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="lastName"
              rules="required"
            >
              <v-text-field
                v-model="user.lastName"
                :label="$t('userForm.lastName')"
                required
                :error-messages="errors"
                maxlength="100"
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="email"
              rules="required"
            >
              <v-text-field
                v-model="user.email"
                :label="$t('userForm.email')"
                :error-messages="errors"
                required
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="username"
              :rules="usernameValidation"
            >
              <v-text-field
                v-model="user.username"
                :label="$t('userForm.username')"
                :error-messages="errors"
                required
                class="font-size-input input-style"
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="password"
              :rules="passwordValidation"
            >
              <v-text-field
                v-model="user.password"
                :label="$t('userForm.password')"
                :error-messages="errors"
                type="password"
                required
                class="font-size-input input-style"
                @click.right.prevent
                @copy.prevent
                @paste.prevent
              >
              </v-text-field>
            </validation-provider>
          </v-col>
          <v-col cols="12" class="pb-0 col-sm-6">
            <validation-provider
              v-slot="{ errors }"
              name="confirmPassword"
              :rules="confirmPasswordValidation"
            >
              <v-text-field
                v-model="user.confirmPassword"
                :label="$t('userForm.confirmPassword')"
                :error-messages="errors"
                required
                class="font-size-input input-style"
                type="password"
                @click.right.prevent
                @copy.prevent
                @paste.prevent
              >
              </v-text-field>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0">
            <validation-provider
              v-slot="{ errors }"
              name="acceptTerms"
              rules="required"
            >
              <v-checkbox
                v-model="user.acceptTerms"
                :error-messages="errors"
                required
              >
                <div slot="label">
                  {{ $t("userForm.acceptTerms") }}
                  <a class="text-decoration-underline">{{
                    $t("userForm.acceptTermsLink")
                  }}</a>
                </div>
              </v-checkbox>
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-column flex-sm-row">
          <v-col cols="12" class="pb-0 d-flex justify-center align-center">
            <v-card-actions>
              <v-btn
                :elevation="0"
                color="#2196f3"
                class="
                  font-weight-bold
                  text-white
                  py-5
                  px-12
                  my-auto
                  ms-md-auto
                  mr-md-12
                "
                small
                @click="handleSubmit"
                :loading="loading"
                :disabled="!valid"
              >
                {{ $t("saveChanges") }}
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "user-form",
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
        acceptTerms: true,
      },
      valid: false,
      loading: false,
    };
  },
  computed: {
    usernameValidation() {
      return {
        required: {},
        min: {
          length: 5,
        },
      };
    },
    passwordValidation() {
      return {
        required: {},
        min: {
          length: 6,
        },
        password: true,
      };
    },
    confirmPasswordValidation() {
      return {
        required: {},
        confirmed: {
          target: "password",
        },
      };
    },
  },
  methods: {
    ...mapActions(["setError", "setSuccess", "goTo"]),
    async handleSubmit() {
      this.valid = await this.$refs.form.validate();

      if (this.valid) {
        if (this.$route.params.id) {
          this.updateUser(this.$route.params.id);
          return;
        }

        this.createUser();
      }
    },
    async createUser() {
      this.loading = true;

      try {
        await this.$api.user.postUserRegister(this.user);
        this.loading = false;
        this.setSuccess(this.$t("userForm.success"));
        this.$router.push("/user");
      } catch (error) {
        this.loading = false;
        this.setError(error);
      }
    },
    async loadUser(id) {
      this.loading = true;

      try {
        const response = await this.$api.user.getUser(id);
        const { firstName, lastName, email, username } = response.data;
        this.user = Object.assign(this.user, {
          firstName,
          lastName,
          email,
          username,
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.setError(error);
        this.$router.push("/user");
      }
    },
    async updateUser(id) {
      this.loading = true;

      try {
        await this.$api.user.putUser(id, this.user);
        this.loading = false;
        this.setSuccess(this.$t("userForm.success"));
        this.$router.push("/user");
      } catch (error) {
        this.loading = false;
        this.setError(error);
      }
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadUser(this.$route.params.id);
    }
  },
};
</script>
