var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left",style:({ 
      backgroundImage: ("linear-gradient(to top, " + _vm.color + " " + _vm.percentage + "%, gray " + _vm.percentage + "%)"),
      height: _vm.isLargeComponent ? '7rem' : '4rem',
      width: _vm.isLargeComponent ? '3rem' : '1rem'
    })}),_c('div',{staticClass:"container",style:({
      backgroundImage: ("linear-gradient(to top, " + _vm.color + " " + _vm.percentage + "%, gray " + _vm.percentage + "%)"),
      height: _vm.isLargeComponent ? '7rem' : '4rem',
      width: _vm.isLargeComponent ? '11rem' : '7rem'
    })}),_c('div',{staticClass:"right",style:({
      backgroundImage: ("linear-gradient(to top, " + _vm.color + " " + _vm.percentage + "%, gray " + _vm.percentage + "%)"),
      height: _vm.isLargeComponent ? '7rem' : '4rem',
      width: _vm.isLargeComponent ? '3rem' : '1rem'
    })})])])}
var staticRenderFns = []

export { render, staticRenderFns }