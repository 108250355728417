<template>
  <div class="pa-6">
    <data-history-report
      :id="$route.params.id"
      :initialDateTime="$route.params.initialDateTime"
      :finalDateTime="$route.params.finalDateTime"
    />
  </div>
</template>

<script>
import DataHistoryReport from "@/components/reports/dataHistory/DataHistoryReport.vue";

export default {
  name: "DataReport",
  components: {
    DataHistoryReport,
  },
};
</script>

<style></style>
