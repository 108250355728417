<template>
  <v-container v-if="!loading" fluid class="py-6 mt-3">
    <v-row class="d-flex aling-center mb-8">
      <div class="d-flex justify-center align-center">
        <div @click="setFetchTanksInterval">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                class="material-icons-round text-blue ms-auto px-4"
                size="30"
              >
                refresh
              </v-icon>
            </template>
            <span>{{ $t("refresh") }}</span>
          </v-tooltip>
        </div>
        <p class="ma-0">
          {{ $t("infoWillBeUpdated", { time: timeToNextRefresh }) }}
        </p>
      </div>
      <v-col md="4" class="my-auto text-end ml-auto">
        <v-btn
          :elevation="0"
          @click="redirectToNewTank"
          color="#2196f3"
          class="font-weight-bold text-white py-5 px-5 my-auto ms-auto"
          small
        >
          <v-icon class="material-icons-round me-3">add</v-icon>
          {{ $t("add") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <tank-card v-for="tank in tanks" :key="tank.id" :tank="tank" />
    </v-row>
  </v-container>
  <loading v-else />
</template>
<script>
import { mapActions } from "vuex";
import Loading from "@/components/Loading.vue";
import TankCard from "@/components/tanks/TankCard.vue";
import { maxTanksAmount, tanksRefreshInterval } from "@/data/constants/tanks";

export default {
  name: "Tank",
  components: {
    Loading,
    TankCard,
  },
  data: function () {
    return {
      tanks: [],
      loading: true,
      intervalTimer: {
        tanksInterval: null,
        secondsToNextRefreshInterval: null,
        time: tanksRefreshInterval,
      },
      maxTanksAmount,
    };
  },
  computed: {
    timeToNextRefresh: (vm) =>
      vm.intervalTimer.time / 1000 < 10
        ? `0${vm.intervalTimer.time / 1000}`
        : vm.intervalTimer.time / 1000,
  },
  methods: {
    ...mapActions(["setError"]),
    async fetchTanks() {
      this.loading = true;
      try {
        const response = await this.$api.tank.getTanksMeasurements();
        this.loading = false;
        this.tanks = response.data.map((tank) => {
          if (tank.tankId) {
            tank.id = tank.tankId;
          }
          return tank;
        });
        this.tanks = this.tanks.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        this.setError(error);
      }
    },
    async fetchTanksWithoutMeasurements() {
      this.loading = true;
      try {
        const response = await this.$api.tank.getTanks();
        this.loading = false;
        const tanksWithoutMeasurements = response.data.filter(
          (item) => !this.tanks.find((tank) => tank.id === item.id)
        );
        this.tanks = this.tanks.concat(tanksWithoutMeasurements);
        this.tanks = this.tanks.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        this.setError(error);
      }
    },
    async setFetchTanksInterval() {
      if (this.intervalTimer.tanksInterval)
        clearInterval(this.intervalTimer.tanksInterval);

      if (this.intervalTimer.secondsToNextRefreshInterval)
        clearInterval(this.intervalTimer.secondsToNextRefreshInterval);

      await this.fetchTanks().then(
        async () => await this.fetchTanksWithoutMeasurements()
      );

      this.intervalTimer.time = tanksRefreshInterval;

      this.intervalTimer.tanksInterval = setInterval(async () => {
        this.intervalTimer.time = tanksRefreshInterval;

        await this.fetchTanks().then(
          async () => await this.fetchTanksWithoutMeasurements()
        );
      }, tanksRefreshInterval);

      this.intervalTimer.secondsToNextRefreshInterval = setInterval(() => {
        this.intervalTimer.time = this.intervalTimer.time - 1000;
      }, 1000);
    },
    redirectToNewTank() {
      this.$router.push("/tanks/new");
    },
  },
  async mounted() {
    await this.setFetchTanksInterval();
  },
  beforeDestroy() {
    clearInterval(this.intervalTimer.tanksInterval);
    clearInterval(this.intervalTimer.secondsToNextRefreshInterval);
  },
  watch: {
    $route() {
      this.$destroy();
    },
  },
};
</script>
