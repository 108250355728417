<template>
  <v-card
    class="card-shadow border-radius-xl mt-6"
    id="basic"
    :disabled="loading"
  >
    <div class="px-6 py-6">
      <h5 class="text-h5 font-weight-bold text-typo">
        {{ $t("report.newShiftsEndsReport") }}
      </h5>
    </div>
    <validation-observer ref="form">
      <v-form v-model="valid" :lazy-validation="true" class="px-6 pb-6 pt-0">
        <v-row class="mt-0">
          <v-col sm="6" cols="12">
            <tank-selector-input
              v-model="report.tankId"
              :label="$t('report.selectATank')"
              required
            />
          </v-col>
          <v-col sm="6" cols="12">
            <company-selector-input
              v-model="report.companyId"
              :label="$t('report.selectACompany')"
              required
            />
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col sm="4" cols="12">
            <shift-selector-input
              v-model="report.shiftId"
              :disabled="!report.companyId"
              :companyId="report.companyId"
              :label="$t('report.selectAShift')"
            />
          </v-col>
          <v-col sm="8" cols="12">
            <label class="text-sm text-body">{{
              $t("report.selectAPeriod")
            }}</label>
            <v-row>
              <v-col cols="6" class="mt-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="initialDateTime"
                  rules="required"
                >
                  <v-text-field
                    v-model="report.dateTimeRange.initialDateTime"
                    class="font-size-input input-style ml-4"
                    :label="$t('report.initialDateTime')"
                    :error-messages="errors"
                    type="date"
                    required
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6" class="mt-1">
                <validation-provider
                  v-slot="{ errors }"
                  name="finalDateTime"
                  rules="required"
                >
                  <v-text-field
                    v-model="report.dateTimeRange.finalDateTime"
                    class="font-size-input input-style ml-4"
                    :label="$t('report.finalDateTime')"
                    :error-messages="errors"
                    type="date"
                    required
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="pb-0 col-sm-6 d-flex justify-end align-center ml-auto mb-3"
          >
            <v-btn
              :elevation="0"
              color="#2196f3"
              class="font-weight-bold text-white py-5 px-12"
              small
              @click="handleSubmit"
              :loading="loading"
            >
              {{ $t("report.generateReport") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </v-card>
</template>
<script>
import CompanySelectorInput from "@/components/form/CompanySelectorInput.vue";
import TankSelectorInput from "@/components/form/TankSelectorInput.vue";
import ShiftSelectorInput from "@/components/form/ShiftSelectorInput.vue";
export default {
  name: "ShiftsReportForm",
  components: {
    CompanySelectorInput,
    TankSelectorInput,
    ShiftSelectorInput,
  },
  data: () => ({
    loading: false,
    valid: false,
    report: {
      dateTimeRange: {
        initialDateTime: null,
        finalDateTime: null,
      },
      companyId: null,
      tankId: null,
      shiftId: null,
    },
  }),
  methods: {
    async handleSubmit() {
      this.valid = await this.$refs.form.validate();

      if (this.valid) {
        const routeOptions = {
          name: "shiftsEndsReport",
          params: {
            initialDateTime: this.report.dateTimeRange.initialDateTime,
            finalDateTime: this.report.dateTimeRange.finalDateTime,
            tankId: this.report.tankId,
            companyId: this.report.companyId,
            shiftId: this.report.shiftId,
          },
        };
        this.$router.push(routeOptions);
      }
    },
  },
};
</script>
