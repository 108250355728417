var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-shadow border-radius-xl mt-6",attrs:{"id":"basic","loading":_vm.loading}},[_c('div',{staticClass:"px-6 py-6"},[_c('h5',{staticClass:"text-h5 font-weight-bold text-typo"},[_vm._v(" "+_vm._s(_vm.$t("userForm.title"))+" ")])]),_c('validation-observer',{ref:"form"},[_c('v-form',{staticClass:"px-6 pb-6 pt-0",attrs:{"lazy-validation":true},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"firstName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-size-input input-style",attrs:{"label":_vm.$t('userForm.firstName'),"error-messages":errors,"maxlength":"100","required":""},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})]}}])})],1),_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"lastName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-size-input input-style",attrs:{"label":_vm.$t('userForm.lastName'),"required":"","error-messages":errors,"maxlength":"100"},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})]}}])})],1)],1),_c('v-row',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-size-input input-style",attrs:{"label":_vm.$t('userForm.email'),"error-messages":errors,"required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}])})],1),_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"username","rules":_vm.usernameValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-size-input input-style",attrs:{"label":_vm.$t('userForm.username'),"error-messages":errors,"required":""},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})]}}])})],1)],1),_c('v-row',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"password","rules":_vm.passwordValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-size-input input-style",attrs:{"label":_vm.$t('userForm.password'),"error-messages":errors,"type":"password","required":""},on:{"contextmenu":function($event){$event.preventDefault();},"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}])})],1),_c('v-col',{staticClass:"pb-0 col-sm-6",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"confirmPassword","rules":_vm.confirmPasswordValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"font-size-input input-style",attrs:{"label":_vm.$t('userForm.confirmPassword'),"error-messages":errors,"required":"","type":"password"},on:{"contextmenu":function($event){$event.preventDefault();},"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();}},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}})]}}])})],1)],1),_c('v-row',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"acceptTerms","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"error-messages":errors,"required":""},model:{value:(_vm.user.acceptTerms),callback:function ($$v) {_vm.$set(_vm.user, "acceptTerms", $$v)},expression:"user.acceptTerms"}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.$t("userForm.acceptTerms"))+" "),_c('a',{staticClass:"text-decoration-underline"},[_vm._v(_vm._s(_vm.$t("userForm.acceptTermsLink")))])])])]}}])})],1)],1),_c('v-row',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-col',{staticClass:"pb-0 d-flex justify-center align-center",attrs:{"cols":"12"}},[_c('v-card-actions',[_c('v-btn',{staticClass:"\n                font-weight-bold\n                text-white\n                py-5\n                px-12\n                my-auto\n                ms-md-auto\n                mr-md-12\n              ",attrs:{"elevation":0,"color":"#2196f3","small":"","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t("saveChanges"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }