<template>
  <div class="empty-state-container pa-2">
    <v-img
      :src="require('@/assets/icons/empty-state.svg')"
      class="empty-state-icon mb-1"
      contain
    />
    <p class="empty-state-title">{{ $t("emptyState") }}</p>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
};
</script>

<style scoped>
.empty-state-container {
  height: 90% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.empty-state-icon {
  width: 3rem;
}

.empty-state-title {
  margin-top: 10px;
  text-align: center;
}
</style>
