<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col>
        <v-tabs
          background-color="transparent"
          class="text-left d-flex justify-center"
        >
          <v-tab :ripple="false" @click="historyTrue">
            <span class="ms-1">{{ $t("dataHistoryReport") }}</span>
          </v-tab>

          <v-tab :ripple="false" @click="historyFalse">
            <span class="ms-1">{{ $t("shiftsEndsReport") }}</span>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col lg="12">
        <data-history-report-form v-if="history"></data-history-report-form>
        <shifts-report-form v-else></shifts-report-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DataHistoryReportForm from "@/components/reports/dataHistory/DataHistoryReportForm.vue";
import ShiftsReportForm from "@/components/reports/shifts/ShiftsReportForm.vue";

export default {
  name: "Settings",
  components: {
    DataHistoryReportForm,
    ShiftsReportForm,
  },
  data() {
    return {
      history: true,
      switche: true,
    };
  },
  methods: {
    historyTrue() {
      this.history = true;
    },
    historyFalse() {
      this.history = false;
    },
  },
};
</script>
