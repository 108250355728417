import { mapActions } from "vuex";

export default {
  data: () => ({
    sensors: [],
  }),
  methods: {
    ...mapActions(["setError"]),
    async fetchSensors() {
      try {
        const response = await this.$api.sensor.getSensors();
        this.loading = false;
        this.sensors = response.data;
      } catch (error) {
        this.setError(error);
      }
    },
  },
};
